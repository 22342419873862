import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    Aw: 0,
    Af: 0,
    Uf: 1.3,
    Ag: 0,
    Ug: 1.1,
    lg: 0,
    psi: 0.08,
  },
  mutations: {},
  actions: {},
  modules: {},
});
