<template>
  <nav>
    <v-app-bar app color="grey lighten-5" clipped-left elevation="3">
      <div class="logo">
        <v-img src="../assets/nerys_horizontal.svg"></v-img>
      </div>
      <v-spacer></v-spacer>
      <v-toolbar-title>
        Simulador de Janelas
      </v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer app width="220" color="indigo" dark clipped permanent>
      <v-list dense nav>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.route"
        >
          <v-list-item-icon>
            <v-img :src="item.icon" width="44"></v-img>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="pa-4 white--text font-weight-light">
          <h4 class="my-3 font-weight-regular">Créditos:</h4>
          <p>
            Filipe Dias Rodrigues, Eng.<br />
            <span class="caption">Adriano Rodrigues & Filhos Lda.</span>
          </p>
        </div>
      </template>
    </v-navigation-drawer>
  </nav>
</template>

<script>
export default {
  computed: {
    items() {
      return [
        {
          title: 'Fixo',
          icon: require('@/assets/1folha.png'),
          route: '/fixo',
        },
        {
          title: '1 Folha',
          icon: require('@/assets/1folha.png'),
          route: '/1f',
        },
        {
          title: '2 Folhas',
          icon: require('@/assets/2folhas.png'),
          route: '/2f',
        },
        {
          title: '3 Folhas',
          icon: require('@/assets/3folhas.png'),
          route: '/3f',
        },
        {
          title: '4 Folhas',
          icon: require('@/assets/4folhas.png'),
          route: '/4f',
        },
      ];
    },
  },
};
</script>

<style scoped>
.logo {
  width: 220px;
  padding: 20px;
  margin-left: -20px;
}
</style>
