import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/2f.vue'),
  },
  {
    path: '/fixo',
    name: 'Fixo',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/fixo.vue'),
  },
  {
    path: '/1f',
    name: '1Folha',
    component: () => import(/* webpackChunkName: "about" */ '../views/1f.vue'),
  },
  {
    path: '/2f',
    name: '2Folhas',
    component: () => import(/* webpackChunkName: "about" */ '../views/2f.vue'),
  },
  {
    path: '/3f',
    name: '3Folhas',
    component: () => import(/* webpackChunkName: "about" */ '../views/3f.vue'),
  },
  {
    path: '/4f',
    name: '4Folhas',
    component: () => import(/* webpackChunkName: "about" */ '../views/4f.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
